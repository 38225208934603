import React from 'react'
import './experience.css'
import { ExperienceItem } from './Timeline-Item/ExperienceItem';
import { EducationItem } from './Timeline-Item/EducationItem';
export const Experience = ({experiences, educations}) => {
  return (
    <section id="experience">
      <div className="container exp-edu__container">
        <div className="exp">
          <h2>Experience</h2>
          <div className="content">
            <ul>
              {
                experiences.map((experience)=>{
                  return (
                    <ExperienceItem experience={experience} key={experience.id}/>
                    )
                })
              }
            </ul>
          </div>
        </div>
        <div className="education">
          <h2>Education</h2>
          <div className="content">
            <ul>
              {
                educations.map((education)=>{
                  return (
                    <EducationItem education={education}/>
                    )
                })
              }
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}
