import React from 'react'
import CV from '../../../assets/CV-JD.pdf'
export const CTA = () => {
  return (
    <div className="cta">
        <a className='btn' href={CV} download>Download resume</a>
        <a className='btn btn-primary' href="#contact">Contact</a>
    </div>
  )
}
