import React from 'react'
import {AiFillHtml5} from 'react-icons/ai'
import {DiCss3Full} from 'react-icons/di'
import {FaReact} from 'react-icons/fa'
import {DiJavascript1} from 'react-icons/di'
import {FaAngular} from 'react-icons/fa'
import {BsFillBootstrapFill} from 'react-icons/bs'
import{SiDotnet} from 'react-icons/si'
import {GrNode} from 'react-icons/gr'
import {DiMongodb} from 'react-icons/di'
import {DiMysql} from 'react-icons/di'
import {SiXamarin} from 'react-icons/si'
import {AiFillAndroid} from 'react-icons/ai'
import {SiSwift} from 'react-icons/si'
import {FaDocker} from 'react-icons/fa'
import {SiTerraform} from 'react-icons/si'
import {SiMicrosoftazure} from 'react-icons/si'
import {SiKubernetes,SiElasticsearch} from 'react-icons/si'
import './SkillIcon.css'
const Icons = {
    "AiFillHtml5": <AiFillHtml5 className='skill__icon'/>,
    "DiCss3Full": <DiCss3Full className='skill__icon'/>,
    "FaReact": <FaReact className='skill__icon'/>,
    "DiJavascript1": <DiJavascript1 className='skill__icon'/>,
    "FaAngular": <FaAngular className='skill__icon'/>,
    "BsFillBootstrapFill": <BsFillBootstrapFill className='skill__icon'/>,
    "SiDotnet": <SiDotnet className='skill__icon'/>,
    "GrNode": <GrNode className='skill__icon'/>,
    "DiMongodb": <DiMongodb className='skill__icon'/>,
    "DiMysql": <DiMysql className='skill__icon'/>,
    "SiXamarin": <SiXamarin className='skill__icon'/>,
    "AiFillAndroid": <AiFillAndroid className='skill__icon'/>,
    "SiSwift": <SiSwift className='skill__icon'/>,
    "FaDocker": <FaDocker className='skill__icon'/>,
    "SiTerraform": <SiTerraform className='skill__icon'/>,
    "SiMicrosoftazure": <SiMicrosoftazure className='skill__icon'/>,
    "SiKubernetes": <SiKubernetes className='skill__icon'/>,
    "SiElasticsearch": <SiElasticsearch className='skill__icon'/>
}
export const SkillIcon = ({icon,}) => {
  return (
    <>
      {Icons[icon]}
    </>
  )
}
