import {Skill} from '../Skill/Skill';
import './Area.css';
export const Area = ({area}) => {
  return (
    <div className="skill__frontend">
        <h3>{area.description}</h3>
        <div className="area__content">
          {
            area.skills.map((skill) => {
              return (
               <Skill key={skill.id} skill={skill}/>
              )
            })
          }
        </div>
    </div>
  )
}
