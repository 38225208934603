import React from 'react'
import './Skills.css'
import { Area } from './components/Area/Area'

const Skills = ({areas}) => {
  return (
    <section id="skill">
      <h2>Skills</h2>
      <div className="container skill__container">
        {
          areas.map((area) => {
            return (
              <Area key={area.id} area={area}/>
            )
          })
        }
      </div>
    </section>
  )
}

export default Skills