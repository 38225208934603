import React from 'react'
import { SkillIcon } from '../../../Icons/SkillIcon'
import './Skill.css'
export const Skill = ({skill}) => {
  return (
    <article className='skill__details' key={skill.id}>
        <SkillIcon icon={skill.icon}/>
        <div>
            <h5>{skill.technology}</h5>
            <small className='text-light'>{skill.seniority}</small>
        </div>
    </article>
  )
}
