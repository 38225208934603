import React from 'react'
import './Header.css'
import { CTA } from './components/CTA'
import JD from '../../assets/logo.png'
import { HeaderSocials } from './components/HeaderSocials'
import { BsLinkedin } from 'react-icons/bs'
import { FaGithub } from 'react-icons/fa'
export const Header = ({name,title,linkedIn,github}) => {
  return (
    <header>
     <div className="container header__container">
      <h1>{name}</h1>
      <h5 className="text-light">{title}</h5>
      <CTA />
      <div className="me">
          <img src={JD} alt="Juan Frausto" />
      </div>
      <div className="header__socials">
        <a href={linkedIn} target="_blank"><BsLinkedin/></a>
        <a href={github} target="_blank"><FaGithub/></a>
      </div>
     </div>
    </header>
  )
}
