import React from 'react'
import './contact.css'
export const Contact = () => {
  return (
    <section id="contact">
      <h2>Contact</h2>
      <div className="container contact__container">
        <form action="">
          <input type="text" name="" id="" placeholder='Your full name' required />
          <input type="email" name="" id="" placeholder='Your email' required />
          <textarea name="message" rows="10" placeholder='Your message' required></textarea>
          <button type='submit' className='btn btn-primary'>Send Message</button>
        </form>
      </div>
    </section>
  )
}
