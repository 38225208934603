import React from 'react'
import './about.css'
import {BsAwardFill} from 'react-icons/bs'
import {BiMobileAlt} from 'react-icons/bi'
import {TfiWorld} from 'react-icons/tfi'
import {RiHomeGearFill} from 'react-icons/ri'
export const About = ({description}) => {
  return (
    <section id="about">
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-text">
            <p>{description}</p>
          </div>
        </div>
        <div className="about__content">
          <div className='about__cards'>
            <article className='about__card'>
              <BsAwardFill className="about__icon" />
              <h5>Experience</h5>
              <small>3+ Years</small>
            </article>
            <article className='about__card'>
              <BiMobileAlt className="about__icon" />
              <h5>Mobile</h5>
              <small>1+ Years</small>
            </article>
            <article className='about__card'>
              <TfiWorld className="about__icon" />
              <h5>Web</h5>
              <small>3+ Years</small>
            </article>
            <article className='about__card'>
              <RiHomeGearFill className="about__icon" />
              <h5>Infrastructure</h5>
              <small>2+ Years</small>
            </article>
          </div>
         
        </div>


      </div>
    </section>
  )
}
