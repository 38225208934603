import './TimelineItem.css'
import { format } from 'date-fns';


export const EducationItem = ({education}) => {
  const startDate = format(new Date(education.begTime), 'MMM yyyy');
    const endDate = education.endTime ? format(new Date(education.endTime), 'MMM yyyy') : 'Current';
    return (
      <li className='list-item'>
        <div className="list-item__header">
          <h5 className='timeperiod'>{startDate} - {endDate}</h5>
          <h3 className='item-comp'>{education.school}</h3>
        </div>
        <div className="list-item__body">
          <div></div>
          <div className='item-body'>
            <h4 className='item-title'>{education.grade}</h4>
            <p className='item-desc'>{education.description}</p>
          </div>
        </div>
      </li>
    )
}
