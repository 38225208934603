import React from 'react'
import './Nav.css'
import {AiOutlineHome} from 'react-icons/ai'
import {FaUser} from 'react-icons/fa'
import {BsGearFill} from 'react-icons/bs'
import {AiOutlineFundProjectionScreen} from 'react-icons/ai'
import {MdContactEmergency} from 'react-icons/md'
import {MdWork} from 'react-icons/md'
import { useState } from 'react'
export const Nav = () => {
  const [active, setActive] = useState('#')
  return (
    <nav>
      <a href="#" onClick={() => setActive('#')} className={active === '#' ? 'active' : ''}><AiOutlineHome/></a>
      <a href="#about" onClick={() => setActive('#about')} className={active === '#about' ? 'active' : ''}><FaUser/></a>
      <a href="#skill" onClick={() => setActive('#skill')} className={active === '#skill' ? 'active' : ''}><AiOutlineFundProjectionScreen/></a>
      <a href="#experience" onClick={() => setActive('#experience')} className={active === '#experience' ? 'active' : ''}><BsGearFill/></a>
      <a href="#contact" onClick={() => setActive('#contact')} className={active === '#contact' ? 'active' : ''}><MdContactEmergency/></a>
    </nav>
  )
}
