import './App.css';
import { Header } from './components/header/Header';
import { Nav } from './components/nav/Nav';
import { About } from './components/about/About';
import { Experience } from './components/experience/Experience';
import { Contact } from './components/contact/Contact';
import Skills from './components/skills/Skills';
import { useEffect,useState } from 'react';
import env from "react-dotenv";

function App() {
  const [info, setInfo] = useState();
  useEffect(()=>{
    const getInfo = async () => {
      const API_URL = env.API_URL;
      const data = await fetch(`${API_URL}/Info/1`);
      const { body } = await data.json();
      setInfo(body);
    }
    getInfo();
  },[])
  return (
    <div className="App">
      {
        info && 
        <>
          <Header name={info.name} title={info.title} linkedIn={info.linkedIn} github={info.github} />
          <Nav/>
          <About description={info.description}/>
          <Skills areas={info.areas}/>
          <Experience experiences={info.experiences} educations={info.educations}/>
          <Contact/>
        </>
      }
      
    </div>
  );
}

export default App;
