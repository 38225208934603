import React from 'react'
import { format } from 'date-fns';
import './TimelineItem.css'
export const ExperienceItem = ({experience}) => {
    const startDate = format(new Date(experience.startDate), 'MMM yyyy');
    const endDate = experience.endDate ? format(new Date(experience.endDate), 'MMM yyyy') : 'Current';
    return (
      <li className='list-item'>
        <div className="list-item__header">
          <h5 className='timeperiod'>{startDate} - {endDate}</h5>
          <h3 className='item-comp'>{experience.company}</h3>
        </div>
        <div className="list-item__body">
          <div></div>
          <div className='item-body'>
            <h4 className='item-title'>{experience.title}</h4>
            <p className='item-desc'>{experience.description}</p>
          </div>
        </div>
      </li>
    )
  }

  